.blur{
    position: relative;
    width: 100%;
    background-color:var(--blue);
}
.video-background{
    position: relative;
    width: 100%;
    height: 100%;
    opacity: .7;
}


.video_buttons{
    position: absolute;
    display: flex;
    z-index: 2;
    top: 70%;
    width: 100%;
    justify-content: center;
}

.video_buttons #learn_more{
    margin-right: 60px;
}

.video_buttons button{
    display: flex;
    padding: 15px 60px;
    background-color: white;
    color: var(--blue);
    font-size: 2rem;
    border: none;
    cursor: pointer;
    opacity: 0.9;
    font-weight: 400;
}
.video_buttons button:hover{
    opacity: 1;
}

.video_text{
    position: absolute;
    right: 0;
    top: 36%;
    z-index: 2;
    color: white;
}

#video_title{
   font-size: 5rem; 
   padding: 0px 110px 6px 30px;
   background-color: var(--orange);
}

.video_text p{
    font-size: 2rem;
    margin-left: 20px;
}

.video_text span{
    text-decoration: 3px underline var(--blue) ;
}

.brand_needs{
    position: absolute;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
}

.brand_needs h1:first-child {
    position: relative;
    color: white;
    font-size: 4rem;
    z-index: 2;
    padding: 0px 8px 10px 8px;
    border-radius: 30px;
    background-color: var(--orange); 

}
.brand_needs h1:nth-child(2) {
    position: relative;
    display: flex;
    color: white;
    margin-top: 20px;
    font-size: 4rem;
    z-index: 2;
    padding: 0px 8px 10px 8px;
    border-radius: 30px;
    background-color: var(--orange);
}


@media screen and (max-width: 1330px){
    
    .brand_needs h1:first-child{
        font-size: 3.4rem;
        width: 90%;
    }
    .brand_needs h1:nth-child(2) {
        font-size: 3.4rem;
    }

    .brand_needs{
        width: 95%;
    }

    #video_title{
        font-size: 3.5rem;
        margin-top: 35px;
    }

    .video_buttons button{
        font-size: 1.8rem;
    }
    

}

@media screen and (max-width: 1150px) {
    
    .brand_needs h1:first-child{
        font-size: 3rem;
        width: 90%;
    }
    .brand_needs h1:nth-child(2) {
        font-size: 3rem;
    }

    .brand_needs{
        margin-top: 50px;
        width: 95%;
    }
}

@media  screen and (max-width: 1000px){
    
    .brand_needs h1:first-child{
        font-size: 2.5rem;
        width: 100%;
    }
    .brand_needs h1:nth-child(2) {
        font-size: 2.5rem;
    }

    .brand_needs{
        margin-top: 60px;
    }
    #video_title{
        font-size: 3rem;
    }
    .video_buttons button{
        margin-top: 20px;
        font-size: 1.5rem;
    }
}

@media  screen and (max-width: 810px){
    .brand_needs{
        width: 90%
    }
    .brand_needs h1:first-child{
        font-size: 1.9rem;
        
    }
    .brand_needs h1:nth-child(2) {
        font-size: 1.9rem;
        margin-top: 10px;
    }
}

@media  screen and (max-width: 700px){
    .video-background{
        width: 100%;
        height: auto;
        aspect-ratio: 9/15.89;
    }
    
    .brand_needs{
        margin-top: 40px;
    }

    .brand_needs h1:first-child{
        font-size: 2rem;
        width: 90%;
    }

    .brand_needs h1:nth-child(2) {
        font-size: 2rem;
    }

    .video_text span{
        text-decoration: underline #5fc6b3 ;
    }
    
    #video_title{
        display: flex;
        font-size: 3rem; 
        width: 200px;
        padding: 0px 23px 0px 10px;
    }

    .video_text p{
        font-size: 1.4rem;
    }
    .video_buttons{
        flex-direction: column;
        width: 100%;
        align-items: center;
    }
    .video_buttons button{
        margin: 0;
        font-size: 1.4rem;
        text-align: center;
        
    }
    .video_buttons #learn_more{
        margin: 0;
        margin-bottom: 20px;
    }

}
