.aboutLanding {
  background-color: var(--blue);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aboutLanding h1 {
  position: absolute;
  top: 220px;
  right: 200px;
  text-align: center;
  width: 630px;
  padding: 20px;
  color: white;
  font-size: 5.5rem;
  font-weight: 400;
  background-image: url("../LandingPage/images/FuzzyLineTop.png");
  background-repeat: no-repeat;
  background-position-y: 55px;
  background-size: contain;
  z-index: 3;
}

#about_orangewave{
    position: absolute;
    width: 100%;
    z-index: 2;
    margin-top: -40px;
}

.aboutLanding button {
  position: absolute;
  top: 500px;
  right: 380px;
}

.aboutLanding img {
  width: 100%;
  opacity: 0.7;
}

.aboutBody {
  background-color: var(--orange);
  display: flex;
  width: 100%;
  height: 100%;
  /* padding-bottom: 30px; */
}

.aboutbubbles {
  width: 35%;
  display: flex;
  justify-content: center;
}

.aboutbubbles img {
  margin-top: 50px;
  height: 83%;
  width: 45%;
}

.aboutText {
  width: 55%;
  color: white;
  padding: 100px 120px 70px 20px;
  font-size: 1.2rem;
}

#fourImages {
  background-color: var(--blue);
  width: 100%;
  height: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#fourImages img {
  width: 90%;
}

#leadershipTeam {
  background-color: var(--orange);
  width: 100%;
  height: 200px;
  display: flex;
}

#leadershipTeam h1 {
  color: white;
  text-align: center;
  padding: 50px;
  font-size: 2.2rem;
  width: 30%;
  font-weight: 400;
}

#leadershipTeam p {
  font-size: 1.3rem;
  color: white;
  width: 70%;
  padding: 60px;
}

#ourClients {
  width: 100%;
  height: 200px;
  background-color: var(--orange);
  display: flex;
}

#ourClients h1 {
  color: white;
  text-align: center;
  padding: 50px;
  font-size: 2.2rem;
  width: 30%;
  font-weight: 400;
}
#ourClients p {
  font-size: 1.3rem;
  color: white;
  width: 70%;
  padding: 60px;
}

#startToday {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0 100px 0;
}

#startToday h1,
#startToday span {
  font-size: 2.5rem;
  font-weight: 400;
  color: var(--blue);
  margin-bottom: 20px;
}

#startToday span {
  text-decoration: underline 3px black;
}

#startToday p {
  width: 50%;
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: -40px;
}

.about_page_clients {
  background-color: var(--blue);
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about_page_clients img {
  height: 400px;
  width: auto;
}

#about_page_btn {
  display: flex;
  position: relative;
}

#about_page_btn button {
  background-color: var(--blue);
}

/* #btn-about-contact {
  margin-right: 50px;
} */

.aboutText h2 {
  display: none;
}

.about_text_mobile{
    display: none;
}

#about_page_btn{
    gap: 50px;
}

@media screen and (max-width: 1300px) {
  .about_page_clients img {
    height: auto;
    width: 90%;
  }

  .aboutbubbles img {
    margin-top: 50px;
    height: 83%;
    width: 55%;
  }
}

@media screen and (max-width: 1000px) {
  .aboutText {
    width: 60%;
    color: white;
    padding: 100px 40px 70px 20px;
    font-size: 1.2rem;
  }

  .aboutbubbles img {
    margin-top: 50px;
    height: 83%;
    width: 70%;
  }
  .about_page_clients {
    height: 300px;
  }
  #leadershipTeam {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    padding: 40px 0;
  }
  #leadershipTeam h1 {
    width: 100%;
    padding: 0;
    margin-bottom: 10px;
  }

  #leadershipTeam p {
    width: 80%;
    padding: 0;
  }

  #ourClients {
    height: 100%;
    background-color: var(--orange);
    padding: 40px 0;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  #ourClients h1 {
    padding: 0px;
    width: 100%;
    margin-bottom: 10px;
  }
  #ourClients p {
    font-size: 1.3rem;
    color: white;
    width: 80%;
    padding: 0px;
  }

  #startToday p {
    width: 80%;
    font-size: 1.3rem;
    text-align: center;
    margin-bottom: -40px;
  }

  #about_orangewave{
    margin-top: -30px;
  }
}

@media screen and (max-width: 1350px) {

    .aboutLanding h1 {
        top: 140px;
        right: 100px;
      }
      
      .aboutLanding button {
        position: absolute;
        top: 250px;
        right: 250px;
      }

}

@media screen and (max-width: 1050px) {
    
    .aboutLanding h1 {
        top: 120px;
        right: 80px;
      }
      
      .aboutLanding button {
        top: 200px;
        right: 200px;
      }
}


@media screen and (max-width: 900px) {
  .aboutText h2 {
    display: flex;
    margin-bottom: 10px;
  }
  .aboutbubbles {
    display: none;
  }

  .aboutText {
    width: 100%;
    color: white;
    padding: 100px 50px 70px 50px;
    font-size: 1.2rem;
  }
  #about_orangewave{
    margin-top: -10px;
  }

  .aboutLanding h1 {
    top: 100px;
    right: 50px;
  }
  
  .aboutLanding button {
    top: 200px;
    right: 150px;
  }
}

@media screen and (max-width: 750px) {

    .aboutLanding img{
        display: none;
    }
    .aboutLanding{
        background-color: var(--orange);
    }

    .aboutLanding h1 {
        position: relative;
        background-color: var(--orange);
        text-align: center;
        width: 90%;
        padding: 20px;
        color: white;
        font-size: 5rem;
        font-weight: 400;
        background-image: none;
        z-index: 3;
        margin-right: 0;
        top: auto;
        right: auto;
    }

    .aboutLanding button{
        position: relative;
        top: auto;
        right: auto;
        margin-top: 0;
    }

    #wave_container_about{
        display: none;
    }

    #about_orange_wave{
        display: none;
    }
}

@media screen and (max-width: 600px) {

    #about_page_btn{
        margin-top: 100px;
        gap: 80px;
    }

    #about_page_btn button{
        margin-top: 0;
    }

    .aboutLanding h1 {
        font-size: 4.3rem;
    }

    .aboutLanding button{
        margin-bottom: 50px;
    }
  .aboutText {
    display: none;
  }

  #about_page_btn {
    flex-direction: column;
  }

  #btn-about-contact {
    margin-right: 0px;
    margin-bottom: -50px;
  }

  .about_page_clients {
    height: 230px;
  }

  .about_text_mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    position: relative;
    overflow-x: hidden;
  }

  .about_text_mobile img{
    width: 400px;
    padding: 20px;
  }

  .hidden{
    width: 90%;
    opacity: 0;
    filter: blur(5px);
    transform: translateX(100%);
    transition: all 1s;
    padding: 0 30px;
    color: white;
  }

  .show{
    color: white;
    display: block;
    position: relative;
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
  }
}

@media screen and (max-width: 500px) {
    .aboutLanding h1 {
        font-size: 3.4rem;
    }
    .aboutLanding button{
        padding: 10px 10px;
        width: 140px;
    }

    #startToday span {
      text-decoration: underline black;
    }
    
}

@media screen and (max-width: 400px) {
  .about_page_clients {
    height: 200px;
  }
  .about_page_clients img {
    width: 100%;
  }
  #fourImages img {
    width: 100%;
  }
}
