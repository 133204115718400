.our_company{
    position: relative;
    width: 100%;
    height: 100%;
    background-color: var(--orange) ;
}

#waves{
    position: absolute;
    width: 100%;
    top: -10%;
    z-index: 5;
}

.company_content{
    width: 50%;
    padding: 50px 100px;
    margin-left: 50px;
}

.company_content p{
    font-size: 1.5rem;
}

.company_content h1{
    font-size: 4rem;
    width: 55%;
    font-weight: 300;
    text-align: center;
}

#Omniverse{
    position: absolute;
    right: 5%;
    top: 10%;
    height: 80%;
}

#company_content_title{
    background-image: url(../images/WhiteFuzzyLine.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: 80%;
    padding: 10px 10px 0px 10px;
}

@media screen and (max-width: 1300px){
    
    .company_content h1{
        width: 48%;
        font-size: 3rem;
    }
    .company_content p{
        font-size: 1.4rem;
    }
    .company_content{
        width: 50%;
        padding: 40px 60px;
        margin-left: 50px;
    }
    
    #Omniverse{
        right: 50px;
        width: 29%;
        height: auto;
    }
    
}

@media screen and (max-width: 1100px){

    .our_company{
        margin-top: 10px;
    }
    #Omniverse{
        margin-top: 50px;
        right: 20px;
        width: 35%;
        height: auto;
    }
    .company_content{
        padding: 40px 40px;
        margin-left: 20px;
    }
    .company_content h1{
        font-size: 2.3rem;
        width: 210px;
    }
    #waves{
        top: -48px;
    }

}

@media screen and (max-width: 900px){
    #Omniverse{
        display: none;
    }
    .company_content{
        width: 80%;
        padding: 0;
        margin: 0 auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: -20px;
    }
    #waves{
        top: -30px;
    }
    .company_content h1{
        margin-top: 10px;
        width: 250px;
        text-align: center;
    }    
    .company_content p{
        text-align: center;
    }
    .company_content div{
        padding: 10px;
        background-color: white;
        border-radius: 10px;
        margin-bottom: -20px;
    }
    
    .company_content div:last-child{
        margin-bottom: 50px;
    }
}


@media screen and (max-width: 700px){
    #waves{
        top: -25px;
    }
}