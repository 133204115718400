.clients_section{
    width: 100%;
    height: 100%;
    background-color: var(--orange);
    display: flex;
    justify-content: center;
    padding-bottom: 120px;
}

.talk_about_us{
    margin-top: 100px;
    width: 85%;
}

.talk_about_us h1{
    font-size: 3.5rem;
    width: 500px;
    text-align: center;
    background-image: url(../images/WhiteFuzzyLine.png) ;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: 25px;
    padding: 10px 10px 10px 10px;
    margin: 0 auto;
}

.client_reviews{
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    gap: 40px;
}

.rate {
    height: 370px;
    width: 300px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px;
    text-align: center;
    position: relative;
}
.rate h2{
    font-weight: 400;
    font-size: 2rem;
    margin-bottom: 20px;
}

.rate p{
    font-style: italic;
    font-size: 1.1rem;
}

.rate img{
    width: 100px;
    height: auto;
}

.client_links{
    display: flex;
    align-items: center;
    position: absolute;
    justify-content: space-around;
    flex-direction: row;
    margin-top: 20px;
    bottom: 30px;
    width: 100%;
}

.client_links a{
    font-size: 1.2rem;
    color: black;
}

#profile{
    width: 100px;
    height: 100px;
    border: 2px solid var(--orange);
    border-radius: 50%;
    overflow: hidden;
}

.rate_container_text {
    text-align: center;
}

.client_logos{
    width: 100%;
    height: 500px;
    background-color: var(--blue);
    display: flex;
    justify-content: center;
    align-items: center;
}

#blueWave{
    position: absolute;
    width: 100%;
    z-index: 2;
    margin-top: -80px;
}

#clientLogos{
    position: relative;
    height: 90%;
    width: auto;
}



@media screen and (max-width: 1200px){
    .client_logos{
        height: 400px;
    }
}


@media screen and (max-width: 1000px){
    #blueWave{
        margin-top: -50px;
    }

    .client_logos{
        height: 350px;
    }

    #clientLogos{
        width: 100%;
    }


}

@media screen and (max-width: 700px){
    #blueWave{
        margin-top: -30px;
    }
    .talk_about_us h1{
        font-size: 2.5rem;
        width: 345px;
        text-align: center;
        background-position-y: 10px;
        padding: 0px;
    }
    .clients_section{
        padding-bottom: 80px;
    }
    .client_logos{
        width: 100%;
        height: 100%;
    }
    #clientLogos{
        height: auto;
        width: 100%;
    }
}

@media screen and (max-width: 400px){
    
    .talk_about_us h1{
        font-size: 2.2rem;
        width: 300px;
        text-align: center;
        background-position-y: 8px;
    }

    #blueWave{
        display: none;
    }
    .client_logos{
        height: 200px;
        width: 100%;
    }
    #clientLogos{
        height: auto;
        width: 100%;
    }
}