/* Modal Overlay */
.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Transparent background */
  display: flex;
  justify-content: center;
  z-index: 1000;
}
/* Modal Content */
.modal-content {
  position: fixed;
  background-color: white;
  margin: 20px;
  height: 90vh;
  padding: 20px;
  width: 55%;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  overflow-y: scroll;
}

/* Close Button */
.close-button {
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: rgb(255, 255, 255);
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  color: black;
  font-weight: 400;
  cursor: pointer;
  outline: none;
  
}

@media screen and (max-width:700px) {
    .modal-content{
        min-width: 95%;
        padding: 2px;
    }
    .close-button{
        background-color: black;
        color: white;
        position: absolute;
        top: 10px;
        right: 10px;
    }

}