
.contact-container{
    width: 100%;
    height: 100%;
}
.contactBackground{
    position: relative;
    width: 100%;
    padding-bottom: 110px;
    background-color: #1ec9b3;
}

.contactForm{
    background-color: rgb(255, 255, 255);
    padding: 40px 80px;
    margin: auto;
    box-sizing: border-box;
}

.input_group{
    margin-bottom: 30px;
    position: relative;
}

.input_group input,textarea{
    width: 100%;
    padding: 10px;
    outline: 0;
    color: black;
    border: none;
    background-color: rgb(227, 227, 227);
    font-size: 15px;
    font-family: inherit;
}

.input_group label{
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    padding: 10px;
    color: black;
    cursor: text;
    transition: 0.2s;
    font-weight: 400;

}
    

.submit-btn{
    padding: 15px 50px;
    background-color: var(--orange);
    border: none;
    cursor: pointer;
    outline: none;
    font-size: 1.2rem;
    font-weight: 400;
}

.input_group input:focus ~ label,
.input_group input:valid ~ label,
.input_group textarea:focus ~ label,
.input_group textarea:valid ~ label{
    top: -35px;
    font-size: 14px;
}


.row{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.row .input_group{
    flex-basis: 46.5%;
}

.input_group textarea{
    resize: none;
}

.serviceCheckbox{
    display: flex;
    flex-direction: column;
    
}
.serviceCheckbox input{
   margin-right: 5px;
   display: inline-block;
   vertical-align: middle;
   margin-bottom: 3px;
}
.serviceCheckbox label{
    font-size: 1.1rem;
}


.contact_text{
    position: absolute;
    top: 140px;
    left: 0;
    width: 30%;
    padding: 120px 100px 100px 140px;

    
}
.contact_text h1{
    font-size: 4rem;
    font-weight: 400;
    color: white;
    width: 60%;
}

.contact_text p{
    color: white;
    font-size: 1.3rem;
    width: 70%;
}

.company_locations{
    width: 100%;
    height: 100%;
    background-color: white;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
}


.company_locations span{
    text-decoration: underline var(--orange);
    font-weight: 400;
}

.company_text{
    width: 80%;
    text-align: center;
    padding: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.company_text h1{
    font-size: 3rem;
    font-weight: 400;
}


.company_text:nth-child(1){
    font-size: 1.4rem;
}

.loaction_map{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    gap: 40px;
    margin-bottom: 50px;
}

.loaction_map img{
    width: 300px;
}

.mobile_form_page{
    display: none;
}

.company_main_paragraph{
    margin-top: 40px;
    width: 80%;
}
@media screen and (max-width: 1350px) {
    .form_container_page{
        display: none;
    }
    .mobile_form_page{
        display: flex;
        position: absolute;
        top: 30%;
        right: 9%;
        width: 50%;
        min-width: 350px;
        height: 50%;
        border-radius: 10px;
        background-image: url('../LandingPage/images/formBackground.png');
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
    }
    .container_button{
        position: relative;
        width: 100%;
        height: 100%;
        border: none;
        background-color: transparent;
        backdrop-filter: blur(2px);
        cursor: pointer;
    }

    
}


@media screen and (max-width: 1300px) {
    .checkbox_container{
        flex-direction: column;
    }
    .row .input_group{
        flex-basis: 100%;
        width: 100%;
    }
    .row{
        flex-direction: column;
    }
    .contactForm{
        padding: 40px 40px;
        min-width: 380px;
    }
    .contact_text{
        position: absolute;
        padding: 130px 100px 50px 100px;
    }

    .contact_text h1{
        width: 70%;
    }
    .contact_text p{
        text-align: center;
    }
}


@media screen and (max-width: 1000px) {
    .contactBackground{
        height: 75vh;
    }

    .mobile_form_page{
        top: 380px;
        right: 50%;
        transform: translateX(50%);
        width: 60%;
    }

    .contact_text{
        position: absolute;
        top: 150px;
        width: 100%;
        text-align: center;
        justify-content: center;
        padding: 0;
        margin: 0 auto;
    }
    .contact_text_container{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .contact_text h1{
        width: 100%;
    }
    .contact_text p{
        margin-top: 10px;
    }
   
}

@media screen and (max-width: 750px) {
    .company_text h1{
        font-size: 2rem;
        margin-bottom: 30px;
    }
    
    .contact_text{
        top: 110px;
    }
    .contact_text h1{
        font-size: 3rem;
    }
    .company_text{
        padding: 50px 10px;
    }
}

@media screen and (max-width: 400px) {
    .contactForm{
       margin: 0;
       padding: 40px 30px;
    }
    .contactBackground{
        height: 90vh;
    }
}
