.terms_container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content:center;
}

.terms_text{
    width: 80%;
    margin: 70px 0px 150px 0px;
    background-color: var(--light_orange);
    padding: 20px;
    border-radius: 20px;
}

.terms_text h2{
    margin-left: 10px;
}

.terms_text h1{
    font-weight: 400;
    font-size: 3rem;
}

.terms_text h3{
    font-weight: 400;
    font-size: 1.4rem;
}

.terms_text p{
    font-size: 1.2rem;
    margin-left: 15px;
}
