.join_us{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0px 100px 0px;
    padding: 20px 0;
}

.join_us h1{
    font-weight: 400;
    font-size: 2.4rem;
}
.join_us span{
    font-weight: 400;
    text-decoration: underline 5px #f36b00;
}

.join_us p{
    margin-top: 20px;
    font-size: 1.5rem;
}

.join_us button{
    margin-top: 60px;
}

.join_us_text{
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

@media screen and (max-width: 1000px) {

    .join_us_text{
        width: 80%;
    }

}

@media screen and (max-width: 1000px) {

    .join_us span{
        font-weight: 400;
        text-decoration: underline #f36b00;
    }
}