.frequent-questions {
  display: flex;
  background-color: var(--blue);
  width: 100%;
  height: 100%;
  display: flex;
  padding-bottom: 100px;
}

.questions-container {
  position: relative;
  width: 75%;
  padding: 60px 120px;
}

.questions-title h1 {
  font-size: 3.5rem;
  font-weight: 400;
}

.questions-title span {
  font-size: 3.5rem;
  font-weight: 400;
  text-decoration: underline 4px var(--orange);
}

.questions button {
  background-color: transparent;
  border: none;
  color: var(--orange);
  position: absolute;
  right: 0;
  font-size: 3rem;
  margin-top: -5px;
  margin-right: 5px;
}

.toggle-button.minus {
  font-size: 4.3rem;
  margin-top: -9px;
  margin-right: 8px;
}

.answers {
  background-color: white;
  border: 2px solid white;
}

.question_title_contianer {
  display: flex;
  position: relative;
  margin: 10px 0;
  align-items: center;
}

.question_answer {
  padding: 10px 20px;
  line-height: 1.5;
  font-size: 1.1rem;
}

.question_top_border {
  background-color: white;
  width: 100%;
  height: 3.5px;
  border: white;
  margin-top: 40px;
}

.question_title_contianer h2 {
  margin-left: 10px;
}

#faqBubbles {
  width: 25%;
}

@media screen and (max-width: 1450px) {
  .questions-container {
    padding: 60px 60px;
  }

  #faqBubbles img {
    width: 80%;
  }
}

@media screen and (max-width: 1250px) {
  #faqBubbles {
    display: none;
  }

  .questions-container {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 960px) {
    
    .question_top_border{
        display: none;
    }

    .grid_container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 20px;
        margin-top: 40px;
        justify-items: center;
    }

    .toggle-button{
        display: none;
    }
      
    .questions{
        position: relative;
        width: 100%;
        height: 400px;
    }

    .question_card{
        position: absolute;
        width: 100%;
        height: 100%;
        transform-style: preserve-3d;
        transition: all 0.5s ease;
    }

    .question_card:hover{
        transform: rotateY(180deg);
    }

    .question_title_contianer{
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 0;
        background-color: white;
        border-radius: 20px;
    }
    .question_title_contianer h2{
        margin: 0;
        padding: 20px;
    }
    .answers{
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
        border: none;
        transform: rotateY(180deg);
        border-radius: 20px; 

    }
    .answers p{
        padding: 20px;
        font-size: calc(120%);
    }

    .questions-container{
        padding: 20px 20px;
    }

    .questions-title {
        display: flex;
        text-align: center;
        justify-content: center;
    }

    .questions-title h1{
        font-size: 2.3rem;
    }
    .questions-title span{
        font-size: 2.3rem;
    }
    
}
