.service-page{
    padding-bottom: 100px;
    width: 100%;
    height: 100%;
}

.service-landing{
    position: absolute;
    top: 300px;
    left: 120px;
}

.service-landing h1{
    font-size: 6rem;
    font-weight: 300;
    text-decoration: underline white;
}

#headline{
    position: relative;
    font-size: 3.4rem;
    width: 80%;
    text-align: center;
    padding: 30px;
    font-weight: 400;


}

#headline span{
    background-image: url(../LandingPage/images/OmniadoCircle.png);
    background-repeat: no-repeat;
    width: 100%;
    padding: 15px 15px;
    margin-left: -5px;
    font-weight: 400;
    background-position-y: 8px;
    background-size: contain;
}

#engage_video{
    position: relative;
    width: 100%;
    height: 100%;
}

.service_text{
    padding: 20px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.our_services{
    text-align: center;
    width: 80%;
    font-size: 1.7rem;
    margin-bottom: 60px;
}

.service_paragraphs{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 20px;
    width: 100%;
}

/* .service_paragraphs p{
    width: 40%;
    font-size: 1.5rem;
    padding: 20px;
    background-color: var(--blue);
    border-radius: 20px;
} */

.service_paragraphs img{
    width: 50%;
    height: auto;
    margin: 50px 0px;
    
}

.service_text_p{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.paragraph_container{
    font-size: 1.5rem;
    display: flex;
    padding: 30px;
    background-color: var(--light_orange);
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: 310px;
    min-height: 300px;
    max-width: 500px;
    height: 100%;
}

.service_text_p p{
    width: 100%;
    height: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;

}

#OrangeInfinity{
    display: block;
}

@media screen and (max-width: 1400px) {
    .service-landing{
        top: 250px;
        left: 80px;
    }
}

@media screen and (max-width: 1266px) {
    .service-landing{
        top: 180px;
        left: 70px;
    }
}

@media screen and (max-width: 1100px){
    .service-landing{
        top: 150px;
        left: 50px;
    }
}

@media screen and (max-width: 1200px) {
    #OrangeInfinity{
        min-width: 500px
    }
    .service-landing h1{
        font-size: 5rem;
    }
    .service-landing button{
        margin-top: 20px;
    }
    .service-landing{
        top: 200px;
        left: 50px;
    }

}

@media screen and (max-width: 1000px) {
    .service-landing{
        top: 140px;
        left: 30px;
    }
    .service-landing button{
        padding: 10px 5px;
        width: 200px;
    }

}

@media screen and (max-width: 800px) {
    .service_text{
        padding: 20px 5px;
    }
    #OrangeInfinity{
        width: 100%;
        min-width: 300px
    }

    .service-landing{
        display: none;
    }

    #headline{
        font-size: 2.5rem;
        padding: 10px;
        width: 100%;
    }

    #service_video_container{
        display: none;
    }

    #service_page_beak{
        display: none;
    }
}