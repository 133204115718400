.main_footer {
  background-color: var(--light_orange);
  width: 100%;
  height: 330px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.footer_logo,
.footer_quick_links,
.footer_contact_us {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center child elements vertically within each div */
  margin: 40px; /* Add small gaps between the divs */
}

.footer_quick_links {
  margin-top: 19px;
}

.footer_logo img {
  width: 230px;
}

.main_footer h2 {
  font-size: 1.7rem;
  font-weight: 400;
  margin-bottom: 10px;
}

.main_footer h3 {
  font-size: 1.2rem;
  font-weight: 400;
}

.main_footer p {
  font-size: 1.2rem;
}

.footer_quick_links li {
  list-style: none;
}
.footer_quick_links li a {
  color: black;
  text-decoration: underline #f36b00;
  font-size: 1.2rem;
  line-height: 1.4rem;
}

.second_footer {
  width: 100%;
  position: relative;
  background-color: white;
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
}

.second_footer p {
  font-size: 1rem;
  font-weight: 400;
  text-decoration: underline #f36b00;
  margin-left: 3px;
}

.second_footer_a{
    margin-right: 40px;
}

.second_footer_p{
    display: flex;
    margin-left: 40px;
}

.second_footer a {
  font-size: 1rem;
  font-weight: 400;
  text-decoration: none;
  padding: 10px;
  color: black;
}

#vertical-line {
  width: 0;
  height: 10px; /* Adjust height as per your requirements */
  border-left: 2px solid var(--orange);
}

.light_orange_wave{
    position: absolute;
    width: 100%;
    margin-bottom: -10px;
    margin-top: -60px;
    z-index: 4;
}
@media screen and (max-width: 1330px) {
    .footer_contact_us{
        margin-bottom: 80px;
    }
}


@media screen and (max-width: 1000px) {
  .main_footer h1 {
    font-size: 1.3rem;
  }
  .footer_logo img {
    width: 200px;
  }
  .main_footer{
    height: 280px;
  }
  .light_orange_wave{
    margin-top: -40px;
  }

}


@media screen and (max-width: 730px) {

    .footer_logo {
        display: none;
    }
    
}

@media screen and (max-width: 650px) {

    .second_footer{
        flex-direction: column-reverse;
        justify-content: center;
        height: 80px;
    }

    .second_footer_a, .second_footer_p{
        margin: 0;
        padding-bottom: 10px;
    }

    .footer_contact_us, .footer_quick_links{
        margin: 20px;
    }
    .footer_quick_links{
        margin-bottom: 40px;
    }
    .light_orange_wave{
        margin-top: -20px;
      }
}

