.about_section{
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: 100px;
    background-color: var(--blue);
}
.page_break{
    width: 100%;
    background-color: black;
    height: 10px;
}

.about_section_text{
    width: 50%;
    height: 45%;
    float: right;
    display: flex;
    flex-direction: column;
    padding: 100px 180px 50px 0px;
}

.about_section_text h1{
    color: white;
    display: flex;
    justify-content: center;
    font-size: 4rem;
    padding: 10px 10px 15px 0px;
}

.about_section_text hr{
    width: 100%;
    border: 2px solid black;
    display: flex;
    margin: 0 auto;
    margin-bottom: 30px;
}

.about_section_text p{
    font-size: 1.4rem;
    color: white;
}

.btn-get-started{
    color: black;
    width: 250px;
    background-color: var(--orange);
    padding: 20px 40px 20px 40px ;
    border: none;
    font-size: 1.5rem;
    opacity: .9;
    margin: 0 auto;
    margin-top: 100px;
    font-weight: 400;
}

.btn-get-started:hover{
    opacity: 1;
}

.about_section_title{
    width: 409px;
    margin: 0 auto;
    background-image: url(../images/OmniadoCircle.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: 100%;
    text-align: center;

}

.about_section_pillers{
    position: relative;
    display: flex;
    width: 100%;
    justify-content:center;
    padding-top: 120px;
}

#pillars{
    display: flex;
    width: 90%;
}

.piller_text{
    position: absolute;
    width: 70%;
    display: grid;
    /* margin-top: ; */
    grid-template-columns: repeat(4, 20%);
    gap: 9%; /* Adjust the gap as needed */
    font-size: 1.2rem;
    text-align: center;
    line-height: 1.5;
}

.piller_text div:nth-child(1){
    margin-left: -25px;
}

.piller_text div:nth-child(2){
    margin-left: -30px;
    margin-right: 15px;
}
.piller_text div:nth-child(3){
    margin-left: -55px;
    margin-right: 30px;
}
.piller_text div:nth-child(4){
    margin-left: -65px;
    margin-right: 50px;
}

#bubbles{
    position: absolute;
    width: 21%;
    margin-top: 50px;
    left: 0;
    padding: 50px 100px;
}

.piller_text h1{
    color: var(--orange);
    font-weight: 400;
    margin-bottom: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.piller_text h1 img{
    width: 45px;
    margin-top: 5px;
    margin-right: 10px;
}

@media screen  and (max-width: 1400px){

    .about_section{
        height: 100%;
    }
    .about_section_text{
        padding: 100px 100px 50px 0px;
    }
    .about_section_text p{
        text-align: center;
    }
    #bubbles{
        width: 25%;
    }
    #pillars{
        display: none;
    }
    .piller_text{
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        position: relative;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
        width: 100%;
        
    }
    .about_section_pillers{
        padding-top: 30px;
    
    }
    .piller_text h1{
        margin-bottom: -50px; 
    }

    .piller_text div{
        padding: 20px;
        background-color: white;
        width: 350px;
        border-radius: 20px;
        font-size: 1.5rem;
    }

    .piller_text div:nth-child(1){
        margin-left: 0;
    }
    
    .piller_text div:nth-child(2){
        margin-left: 0;
        margin-right: 0;
    }
    .piller_text div:nth-child(3){
        margin-left:  0;
        margin-right: 0;
    }
    .piller_text div:nth-child(4){
        margin-left:  0;
        margin-right: 0;

    }
   
    
}  

@media screen  and (max-width: 850px){
    #bubbles{
        display: none;
    }

    .about_section h1{
        font-size: 3rem;
    }
    .about_section_title{
        width: 320px;
    }
    .about_section_text{
        width: 80%;
        padding: 0;
        margin: 50px auto;
        float: none;
    }
    .piller_text{
        margin-top: -50px;
    }

}

@media screen  and (max-width: 400px){
   
    .about_section_text{
        width: 90%;
    }

    .piller_text div{
        padding: 20px 10px
    }

}

