.not-found {
  display: grid;
  align-content: center;
  height: 80vh;
  text-align: center ;
}

.not-found-text{
    margin-bottom: 15vh;
}

.not-found h1 {
  font-size: 6rem;
  color: #ff5733;
  margin-bottom: 1rem;
  font-weight: bold;

}

.not-found p {
  font-size: 1.5rem;
  color: #333;
  

}
