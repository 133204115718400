.contactBar {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: space-between;
  background-color: var(--light_orange);
}

.leftNav {
  display: flex;
  justify-content: space-around;
  margin-left: 60px;
}

/* .leftNav h6{
     margin-bottom: 3px;
} */

.scial_media_btn{
  display: none;
}

.phone,
.email {
  display: flex;
  align-items: center;
  font-size: 1.8rem;
}

#phoneimg,
#emailimg {
  width: 30px;
  height: auto;
  padding: 10px;
  margin-left: 30px;
}

.rightNav {
  display: flex;
  flex-direction: row;
  margin-right: 50px;
  box-sizing: border-box;
  align-items: center;
}

.social_links {
  list-style: none;
  padding: 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.social_links li {
  background: #fff;
  display: flex;
  border-radius: 50px;
  overflow: hidden;
  align-items: center;
  cursor: pointer;
  width: 30px;
  height: 30px;
  transition: 0.3s ease-in-out;
}

.social_links li:hover {
  border-radius: 50px;
  width: 140px;
}

.social_links li .icon {
  font-size: 20px;
  min-width: 30px;
  max-width: 30px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social_links li .text {
  text-decoration: none;
  display: block;
  padding: 20px 20px;
  font-size: 16px;
  font-weight: bold;
}

/* icon style start */
.social_links li.facebook .icon {
  background: #4267b2;
}

.social_links li.tiktok .icon {
  background: black;
}

.social_links li.linkedin .icon {
  background: #0a66c2;
}

.social_links li.instagram .icon {
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
}
.social_links li.omniado_email .icon,
.social_links li.omniado_phone .icon {
    background: black;
}

/* icon style end */

/* text style start */
.social_links li.facebook .text {
  color: #4267b2;
}

.social_links li.tiktok .text {
  color: black;
}

.social_links li.linkedin .text {
  color: #0a66c2;
}

.social_links li.instagram .text {
  color: purple;
}

.social_links li.omniado_email a,
.social_links li.omniado_phone a {
    color: black;
    
}

.social_links .omniado_phone a,
.social_links .omniado_email a{
    text-decoration: none;
    padding: 0px 5px;
    display: block;
    font-size: 16px;
    font-weight: bold;
    flex-direction: row;

}


.navbar {
  display: flex;
  width: 100%;
  align-items: center;
}

.logo {
  display: flex;
  margin-left: 30px;
  margin-right: 10px;
  cursor: pointer;
}

#mainLogo {
  width: 300px;
  height: auto;
  padding: 20px;
}

.links {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-left: 2rem;
}
.links li {
  position: relative;
  list-style: none;
}
.links li a {
  position: relative;
  color: black;
  text-decoration: none;
  font-size: 1.4rem;
  padding: 20px;
  font-weight: 400;
}

.links li a:hover {
  color: var(--orange);
  opacity: 0.9;
}

.nav-link.active::before {
  content: "";
  position: absolute;
  top: 20px;
  left: 5px;
  width: 100%;
  height: 60%;
  background-image: url("./NavIcons/MenuTab.png"); /* Replace with the path to your image */
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -1; /* Place the image behind the link */
}

.omTalent-img {
  width: 100px;
  height: auto;
  margin-top: 10px;
  padding: 5px 10px 2px 10px;
  background-color: black;
  border-radius: 10px;
  border: 3px solid black;
}

.OmTalent-btn:hover img {
  border: 3px solid var(--blue);
}

.menu-icon {
  display: none;
  cursor: pointer;
  position: absolute;
  font-size: 30px;
  right: 180px;
  z-index: 8;
}

.OmTalent-btn {
  list-style: none;
  right: 30px;
  position: absolute;
  cursor: pointer;
  z-index: 8;
  margin-bottom: 5px;
  background-color: transparent;
}

.OmTalent-btn a{
  display: block;
}

.menu-list {
  position: absolute;
  top: 150%;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(250, 181, 137, 0.5);
  z-index: 1;
  display: flex; /* Display links in a row */
  margin-right: 100px;
}

/* Menu item */
.menu-item {
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  color: var(--blue);
  transition: background-color 0.2s;
  flex: 1; /* Distribute available space equally among links */
  text-align: center; /* Center-align link content */
  border-left: 1px solid #ccc; /* Add a separator between links */
}

/* Apply separator to all links except the first one */
.menu-item:first-child {
  border-left: none;
}

.menu-item:hover {
  background-color: #f0f0f0;
}

.caret {
  margin-left: 5px;
}

.caret-up::before {
  content: "\25B2"; /* Up-caret Unicode character */
}

.caret-down::before {
  content: "\25BC"; /* Down-caret Unicode character */
}

.mobile_social_links{
    display: none;
}


@media screen and (max-width: 1330px) {
  
  .rightNav{
    flex-direction: column;
    align-items: baseline;
    margin-bottom: -20px;
    
  }

  .scial_media_btn {
    display: flex;
    background-color: var(--blue);
    border: 1px solid black;
    font-size: 30px;
    align-self: flex-start;
    margin-top: 20px;
    align-items: center;
    padding: 2px 10px;
    z-index: 10;
    margin-bottom: 20px;
  }

  .scial_media_btn.open {
    background-color: var(--orange);
    border: 1px solid var(--orange);
  }

.mobile_social_links{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.social_links li .text {
    padding: 20px 30px;
}

.social_links li:hover {
    width: 100%;
  }

.social_links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    transform: translateY(150%);
    transition: all .5s ease ;
    opacity: 0;
  }

  .button_container{
    background-color:var(--blue);
    width: 210px;
    z-index: 10;
  }

  .social_links.open{
    transform: translateY(0%);
    opacity: 1;
  }

  .menu-list {
    flex-direction: column;
    position: relative;
    width: 100%;
    top: 0;
    background: transparent;
    border: none;
    box-shadow: none;
    transition: none;
    z-index: 120;
  }

  .menu-list.open {
    animation: slide-down 1s ease;
  }

  .menu-item {
    color: black;
    padding: 5px 10px 5px 45px ;
    text-align: left;
    font-size: 1.3rem;
    font-weight: 400;
  }

  .menu-item:hover {
    color: var(--orange);
  }

  .menu-icon {
    display: block;
  }
  .OmTalent-btn.active {
    position: fixed;
  }
  .links {
    display: block;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    width: 230px;
    height: 100%;
    background-color: var(--blue);
    padding: 10px 0;
    z-index: 7;
    margin: 0;
    align-items: none;
    transition: transform 0.3s ease-in-out;
    transform: translateX(110%);
    
  }
  .links.active {
    display: block;
    transform: translateX(0);
  }
  .links li:first-child {
    margin-top: 80px;
  }

  .links li {
    padding: 10px;
  }

  .links.active li {
    opacity: 0.9;
  }

  .menu-icon.active {
    position: fixed;
  }
  .contactBar {
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    position: fixed;
    width: 230px;
    right: -30px;
    bottom: 20px;
    z-index: 8;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
    background-color: transparent;
  }
  .contactBar.active {
    transform: translateX(0);
  }

  .email,
  .phone {
    display: none;
  }

  .nav-link.active::before {
    background-image: none;
  }

  @keyframes slide-down {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes slide-up {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(-10px);
    }
  }
}

@media screen and (max-width: 600px) {
  #mainLogo {
    width: 150px;
  }
  .links {
    width: 210px;
    margin-top: -15px;
  }
  .logo {
    margin: 0;
  }
  .menu-icon {
    font-size: 25px;
    right: 120px;
    transition: transform 0.4s ease-in-out;
  }
  .menu-icon.active {
    transform: translateX(-40px);
  }
  .OmTalent-btn.active {
    transform: translateX(-15px);
  }
  .omTalent-img {
    width: 60px;
    margin-right: -15px;
  }

  .contactBar {
    margin-right: -10px;
    
  }
}

/* @media screen and (max-height: 850px){
    .links li {
        padding: 8px 10px;
    }
}
@media screen and (max-height: 800px){
    .links li {
        padding: 4px 10px;
    }
} */