*{
  margin: 0;
  padding: 0;

  font-family: "halyard-display", sans-serif;
  font-weight: 300;
  font-style: normal;
  
}

img{
  user-select: none;
  pointer-events: none;
}

button{
  cursor: pointer;
  outline: none;
}

:root{
  --blue: #5fc6b3;
  --light_orange: #fab589;
  --orange: #f36b00;
}

*:focus {
  outline: none;
}

