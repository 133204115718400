.portfolioLanding{
    background-color: var(--blue);
    position: relative;
    aspect-ratio: 16/7;
}

.portfolioLanding img{
    position: relative;
    opacity: .7;
}
.portfolioLanding h1{
    position: absolute;
    top: 0;
    color: white;
    right: 150px;
    font-size: 7rem;
    background-image: url(../LandingPage/images/FuzzyLineTop.png);
    background-position-y: 100px;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 50px 50px 50px 50px ;
}

.portfolioLanding button{
    position: absolute;
    top: 190px;
    right: 190px;
    color: var(--blue);
}

.portfolioLanding h4{
    position: absolute;
    top: 180px;
    right: 190px;
    font-size: 3rem;
}

.portfolioLanding span{
    text-decoration: underline white;
}
.ourPortfolio{
    position: relative;
    width: 100%;
    display: flex;
    height: 100%;
    background-color: var(--orange);
    align-items: center;
    padding: 30px 0px;
}

.ourPortfolio h1{
    font-weight: 400;
    text-decoration: underline 4px var(--blue);
    margin-bottom: 10px;
}

.ourPortfolio p{
    font-size: 1.4rem;
}

#portfolio_clients{
    padding-bottom: 40px;
}

.portfolio_orange_wave{
    position: relative;
    width: 100%;
    height: 100px;
    margin-bottom: -90px;
    background-color: var(--orange);
}

.portfolio_orange_wave img{
    position: absolute;
    width: 100%;
    z-index: 2;
    margin-top: -70px;
}

.ourPortfolio_text{
    width: 75%;
    padding: 30px 140px;
    color: white;
}

#portfolioGetStarted{
    display: none;
}
#portfolio_text_title{
    font-size: 3rem;
}

@media screen and (max-width: 1100px) {
    .ourPortfolio_text{
        padding: 30px 100px 30px 50px;
    }
    .portfolio_orange_wave img{
        margin-top: -40px;
    }
    #portfolio_watermark{
        display: none;
    }
    .ourPortfolio{
        justify-content: center;

    }   
    .ourPortfolio h1{
        font-weight: 400;
        text-decoration: underline #5fc6b3;
        margin-bottom: 10px;
        width: 100%;
    }
    .ourPortfolio_text{
        padding: 0px;
        width: 95%;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
    }

    .ourPortfolio_text p{
        width: 90%;
    }
}

@media screen and (max-width: 900px) {
    .portfolioLanding h1{
        top: -50px;
        right: 50px;
       
    }
    
    .portfolioLanding button{
        top: 130px;
        right: 140px;
       
    }
    
    .portfolioLanding h4{
        position: absolute;
        top: 120px;
        right: 110px;
        font-size: 3rem;
    }
}


@media screen and (max-width: 768px) {
    #portfolio_clients{
        padding-bottom: 10px;
    }
    .about_page_clients img{
        width: 100%;
    }
    #portfolio_watermark{
        display: none;
    }

    #portfolioGetStarted{
        display: block;
    }

    .portfolioLanding{
        display: none;
    }

    .portfolio_orange_wave{
        display: none;
    }

    #portfolio_text_title{
        font-size: 4rem;
    }
}   